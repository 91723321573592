<template>
  <div class="share-box">
    <van-nav-bar class="nav-bar" :title="$t('home.dealerCenter')"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'">
      <i slot="left" class="iconfont icon-jiantou1" @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'">{{ $t('home.dealerCenter') }}</span>
      </template>
      <template #right>
        <van-popover v-model="showPopover" theme="dark" trigger="click" :actions="actions" placement="bottom-end"
          @select="onSelect">
          <template #reference>
            <button>{{ $t('home.detail') }}</button>
          </template>
        </van-popover>
      </template>
    </van-nav-bar>
    <div class="share-content">
      <div class="banner">
        <img class="banner-image" :src="bannerData[0] ? bannerData[0].picture : ''" />
      </div>
      <div class="cell-space"></div>
      <div class="tabel-list">
        <div class="tabel-item" v-for="(item, index) in tabelList" :key="index" @click="itemClick(item.act)">
          <img class="row-icon" v-if="!item.isText" :src="item.image" />
          <span v-else class="item-key">{{ item.key }}</span>
          <span class="table-title">{{ $t(item.title) }}</span>
        </div>
      </div>
      <div class="cell-space"></div>
      <div class="inviteLink">
        <div class="inviteLink-title">{{ $t('home.yourReferralLink') }}</div>
        <div class="inviteLink-content">
          {{ openShareUrl }}
        </div>
        <div class="inviteLink-btns">
          <div class="inviteLink-btn color-first" @click="copyEvent()">
            {{ $t('home.copyLink') }}
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="show">
      <div class="commission-box">
        <div class="commission-title">{{ $t('home.withdrawCommission') }}</div>
        <input type="text" v-model="eeuiInpVal" :placeholder="$t('home.withdrawalCommissionAmount')" />
        <div class="commission-btn-box">
          <p @click="show = false">{{ $t('common.cancel') }}</p>
          <p @click="handleGetCommissionMoney"> {{ $t('common.confirm') }}</p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import request from '@/utils/request'
import { formatDate } from '@/utils/unique'
import { Popup } from 'vant'
import { getBannerOfShare } from '@/api/comments.js'
import { Dialog } from 'vant'

export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      show: false,
      showPopover: false,
      actions: [{ text: this.$t('home.calculateCommission'), id: 0 }, { text: this.$t('home.withdrawalProfile'), id: 1 }],
      tabelList: [
        {
          act: 'apply',
          title: 'home.agentSetup',
          image: require('@/assets/images/delegateMember_center/flsq.png'),
          isText: false,
        },
        // {
        //   act: "share",
        //   // 邀请好友
        //   title: "Liên kết giới thiệu",
        //   image: require("@/assets/images/delegateMember_center/yqhy.png"),
        //   isText: false
        // },
        // {
        //   //   act: 'commission',
        //   act: "",
        //   // 代理佣金
        //   title: "Tiền giới thiệu",
        //   isText: true,
        //   key: 0
        // },
        {
          act: '',
          title: 'home.numberInvitations',
          isText: true,
          key: 0,
        },
      ],
      proxyList: [],
      userInfo: {},
      receiveMoney: 0,
      eeuiInpVal: 0,
      openShareUrl: '',
      bannerData: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.findProxyList()
    this.openShare()
    this.getBanner()
  },
  mounted() { },
  methods: {
    async getBanner() {
      const { data } = await getBannerOfShare()

      if (data.ret == 1) {
        this.bannerData = data.data
      }
    },
    onSelect(action) {
      if (action.id == 0) {
        this.$router.push('/user-agent')
      } else {
        this.$router.push('/share-money-list')
      }
    },
    handleRevenue() {
      this.show = true
    },
    async handleGetCommissionMoney() {
      if (!this.eeuiInpVal) {
        this.$toast(this.$t('home.amountIsIncorrect'))
        return false
      }
      const { data } = await request({
        method: 'post',
        url: 'users/ref/withdraw',
        data: {
          amount: this.eeuiInpVal,
        },
        params: {
          amount: this.eeuiInpVal,
        },
      })
      if (data.ret === 1) {
        this.findProxyList()
        this.show = false
      }
      this.$toast(data.msg)
    },
    itemClick(act) {

      if (act && act !== '') {
        this.commitEvent()
      }
    },
    async commitEvent() {
      const { data } = await request({
        method: 'post',
        url: 'agent/handle/apply',
        params: {
          content: {
            shenbianggc: false,
            shenbian: false,
            shenbian6hc: false,
            shenbiantc: false,
            shenbianpl: '',
            tuiguang: '',
            jianyi: '',
          },
        },
      })
      if (data.ret === 1) {
        Dialog.alert({
          message: this.$t('home.successfulAgentApplication'),
        }).then(() => {
          this.$router.back()
        })
      } else {
        Dialog.alert({
          message: data.msg,
          confirmButtonText: this.$t('common.confirm'),
        }).then(() => {
          this.$router.back()
        })
      }
    },
    async findProxyList() {
      const { data } = await request({
        url: 'users/ref/total',
      })
      if (data.ret === 1) {
        this.eeuiInpVal = data.data.amount * 1
        this.tabelList[1].key = `${data.data.total_times} ${this.$t('home.member')}`
        this.proxyList = data.data.lists
        this.proxyList.forEach((_) => {
          _.dateTime = formatDate('Y-m-d H:i', _.indate)
        })
      } else {
        this.eeuiInpVal = 0
        this.tabelList[2].key = '0.00'
        this.tabelList[3].key = `0 ${this.$t('home.member')}`
        this.proxyList = []
        this.$toast(data.msg)
      }
    },

    async openShare() {
      const {
        data: { data, ret },
      } = await request({
        method: 'get',
        url: 'other/config',
        params: { act: 'share' },
      })
      if (ret === 1) {
        this.openShareUrl = data.text + this.userInfo.id
      }
    },
    copyEvent() {
      const _this = this;
      _this.$copyText(_this.openShareUrl).then(() => {
        _this.$toast(_this.$t('home.sharelinkFriendsShare'))
      }, err => {
        _this.$toast(_this.$t('home.unableCopyShareLink'))
      }
      )
    },
    getShowDateTime(val) {
      if (val) {
        return formatDate('Y-m-d H:i', val)
      }
    },
  },
}
</script>

<style lang="less" scoped>
// 弹性盒子
.flex(@Justify) {
  display: flex;
  justify-content: @Justify;
  align-items: center;
  flex-wrap: wrap;
}

// 文字单行显示
.textOnly {
  overflow: hidden;
  text-overflow: ellipsis; //文本溢出显示省略号
  white-space: nowrap; //文本不会换行
}

.share-box {
  height: 100vh;
  font-family: 'PingFang-Regular';
  position: relative;
  box-sizing: border-box;

  .nav-bar {
    width: 100%;
    // z-index: 9999;
  }

  .van-nav-bar {
    /deep/ .van-nav-bar__content {
      // background-color: #ff0000;
    }

    /deep/ .van-nav-bar__title {
      // color: #fff;
    }

    button {
      background-color: #eeeeee;
      border: none;
      border-radius: 5px;
      color: rgb(51, 20, 20);
      font-size: 34px;
    }

    .iconfont {
      font-size: 44px;
      // color: #fff;
    }

    /deep/ .van-popover {
      .van-popover__content {
        width: 405px;
      }

      /deep/ .van-popover__action {
        /deep/ .van-popover__action-text {
          width: 100px;
        }
      }
    }
  }

  >.share-content {
    height: calc(100% - 92px);
    background-color: #f4f8f9;
  }

  .banner {
    width: 100%;
    height: 300px;
  }

  .navbar-title {
    font-size: 32px;
    color: #ffffff;
  }

  .banner-image {
    width: 100%;
    height: 300px;
  }

  .cell-space {
    width: 100%;
    height: 20px;
  }

  .cell-box {
    height: 98px;
    background-color: white;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1px;
  }

  .cell-left-box {
    flex-direction: row;
    margin-left: 32px;
    align-items: center;
  }

  .cell-left-image {
    width: 38px;
    height: 38px;
  }

  .cell-left-title {
    font-size: 30px;
    color: #333333;
    margin-left: 24px;
  }

  .cell-right-box {
    flex-direction: row;
    margin-right: 32px;
    align-items: center;
  }

  .cell-right-desc {
    color: #999999;
    font-size: 28px;
    margin-right: 14px;
  }

  .cell-right-image {
    width: 14px;
    height: 28px;
  }

  .bottom-view-box {
    width: 100%;
    background-color: white;
    flex-direction: column;
  }

  .section {
    flex-direction: row;
  }

  .row {
    width: 250px;
    height: 200px;
    background-color: white;
    border-color: #efefef;
    border-width: 1px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .row-icon {
    width: 70px;
    height: 70px;
  }

  .row-text {
    color: #333333;
    font-size: 28px;
    margin-top: 36px;
  }

  .navbar-right-title {
    width: 110px;
    height: 50px;
    text-align: center;
    font-size: 30px;
    color: #710805;
    background-color: #fff;
    margin-right: 15px;
    line-height: 50px;
    border-radius: 6px;
  }

  .tabel-list {
    width: 710px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 20px;
    border-radius: 20px;
    overflow: hidden;
  }

  .tabel-item {
    width: 355px;
    height: 180px;
    background-color: white;
    border-color: #efefef;
    border-width: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .table-title {
    font-size: 28px;
    margin-top: 20px;
    color: #4f4f4f;
  }

  .item-key {
    font-size: 38px;
    color: #4d0000;
  }

  .list-cont {
    width: 100%;
    background: #fff;
  }

  .receive {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 120px;
    height: 45px;
  }

  .receive-img {
    width: 110px;
    height: 40px;
    position: absolute;
    top: 2px;
    right: 2px;
  }

  .receive-text {
    font-size: 28px;
    color: #fff;
    position: absolute;
    top: 2px;
    right: 5px;
    z-index: 99;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .list-cont-title-div {
    width: 100%;
    height: 70px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-color: #efefef;
    border-bottom-width: 1px;
  }

  .list-cont-title {
    font-size: 30px;
    padding-left: 30px;
    color: #4f4f4f;
  }

  .cont-list {
    height: calc(100vh - 850px);
    overflow-y: auto;
  }

  .item-list {
    width: 100%;
    height: 80px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-color: #efefef;
    border-bottom-width: 1px;
    font-size: 30px;
  }

  .item-img {
    width: 40px;
    height: 40px;
    margin-left: 30px;
    margin-right: 10px;
  }

  .item-data {
    height: 40px;
    line-height: 40px;
    color: #4f4f4f;
  }

  .item-money {
    color: #ff0000;
  }

  .lottery-more {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: flex-end;
    background-color: rgba(0, 0, 0, 0);
    z-index: 100;
  }

  .more-top {
    width: 40px;
    height: 40px;
    margin-top: 6px;
    margin-right: 34px;
    color: #464646;
    font-size: 52px;
    content: 'tb-triangle-up-fill';
  }

  .more-box {
    position: absolute;
    top: 26px;
    right: 16px;
    width: 264px;
    border-radius: 12px;
    background-color: #464646;
  }

  .more-item {
    height: 86px;
    font-size: 26px;
    line-height: 86px;
    text-align: center;
    color: #ffffff;
  }

  .more-line {
    width: 264px;
    height: 1px;
    background-color: #333333;
  }
}

.commission-box {
  width: 620px;
  padding: 40px;

  >.commission-title {
    font-size: 34px;
  }

  >input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #008577;
    font-size: 34px;
  }

  >.commission-btn-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;

    >p {
      font-size: 32px;
      margin-left: 30px;
    }

    >p:nth-last-child(1) {
      color: #008577;
    }
  }
}

.inviteLink {
  background-color: #fff;
  height: 338px;
  width: 710px;
  margin-left: 20px;
  border-radius: 20px;
  font-size: 30px;
  .flex(center);

  .inviteLink-title {
    width: 100%;
    text-align: center;
    color: #ff00e4;
    font-weight: 700;
  }

  .inviteLink-content {
    width: 570px;
    border: 1px solid #625aff;
    // height: 80px;
    line-height: 50px;
    border-radius: 10px;
    padding: 10px 15px;
  }

  .inviteLink-btns {
    width: 80%;
    .flex(space-around);

    .inviteLink-btn {
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
      color: #fff;
      height: 70px;
      line-height: 70px;
      border-radius: 35px;
    }

    .color-first {
      background-color: #ab4eb9;
    }

    .color-second {
      background-color: #6c00ff;
    }
  }
}
</style>
